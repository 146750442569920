import React from 'react';
// import Autosuggest from 'react-autosuggest';

const TopNavbar = () => {
  

  return (
    <nav className="top-navbar">
      <div className="top-navbar-logo">
        <img src="report-left-logo.png" style={{ width: "150px", height: "30px" }} title="Report" alt="Report Logo" />
      </div>
   
      <div className="top-navbar-links">
        {/* Add additional navbar links or buttons here */}
        <img src="report-right-logo.png" style={{ width: "150px", height: "30px" }} title="Report" alt="Report Logo" />

        
      </div>
    </nav>
  );
};

export default TopNavbar;
