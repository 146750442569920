import React, { useState } from 'react';
import TabbedViewer from '../TabbedViewer'; // Assuming this is your Treemap component
import './TabbedTableDiagram.css';

const ConsumerTabbedTable = ({ data, selectedColumns }) => {
  const [activeTab, setActiveTab] = useState('table');

  // Get object keys for table headers

  
  console.log(data);

 
// Filter each object in the array based on `selectedColumns`, always including `category`
const filteredData = data.map(item => {
  return Object.keys(item)
    .filter(key => selectedColumns.includes(key) || key === "Category") // Include `selectedColumns` and `category`
    .reduce((obj, key) => {
      obj[key] = item[key]; // Add the filtered key-value pairs to the new object
      return obj;
    }, {});
});


  console.log("Data",filteredData,selectedColumns);
  console.log("Data",data);
  const object = filteredData.length > 0 ? Object.keys(filteredData[0]) : [];
  return (
    <div className="tab-container">
      <div className="tabs">
        <button className={activeTab === 'table' ? 'active' : ''} onClick={() => setActiveTab('table')}>
          Table
        </button>
      </div>
      <div className="content">
        {activeTab === 'table' && (
          <div className="horizontal-table">
            <div className="table-row header-row">
              {object.map((key, index) => (
                <div className="table-cell header" key={index}>{key.charAt(0).toUpperCase() + key.slice(1)}</div>
              ))}
            </div>
            {filteredData.map((item, rowIndex) => (
              <div className="table-row" key={rowIndex}>
                {object.map((key, colIndex) => (
                  <div className="table-cell" key={colIndex}>{item[key]}</div>
                ))}
              </div>
            ))}
          </div>
        )}
      
      </div>
    </div>
  );
};

export default ConsumerTabbedTable;
