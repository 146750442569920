import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, DoughnutController, RadialLinearScale } from 'chart.js';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

ChartJS.register(Title, Tooltip, Legend, ArcElement, DoughnutController, RadialLinearScale, ChartDataLabels);


// const url = 'https://api-reports.fornaxenergytech.com';
// const url = 'http://fornax:5000';
const url =  process.env.REACT_APP_API_URL;


const OtherComponent = ({setOrganizationId,handleRefresh,selectedData,setSelectedData,refresh,project, setProjects}) => {
  const [data1, setData1] = useState(null);
  const [corporate, setCorporate] = useState([]);
  const [circle, setCircle] = useState([]);
  const [division, setDivision] = useState([]);
  const [subdivision, setSubdivision] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  // const[subStation,setSubStation] = useState([]);
const[filteredproject,setFilteredProject] = useState([]);

  useEffect(() => {

    const timeout = setTimeout(() => {
      setError(true);
      setLoading(false);
    }, 5000); // 5-second timeout for data fetch
  
    axios.get(`${url}/api/Organization/Data`)
      .then((res) => {
        setData1(res.data);
        setLoading(false);
        clearTimeout(timeout);
      })
      .catch((err) => { 
        console.error('Error fetching data:', err)
        setError(true);
        setLoading(false);   
  });

  
  return () => clearTimeout(timeout);

  }, []);


  useEffect(() => {
    const timeout = setTimeout(() => {
      setError(true);
      setLoading(false);
    }, 5000); // 5-second timeout for data fetch
  
    axios.get(`${url}/api/SubstanceView/Data`)
      .then((res) => {
        // setSubStation(res.data.substation);
        setProjects(res.data.project);
        // setFilteredSubStation(res.data.substation);
        setFilteredProject(res.data.project);
        // setLoading(false);
        clearTimeout(timeout);
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        setError(true);
        setLoading(false);
      });
  
    return () => clearTimeout(timeout);
  }, [refresh]);

  // const[refresh,setRefresh] = useState(false);

  useEffect(() => {
    if (!data1) return;
  
    const splitDataByType = (data) => {
      return data.reduce((acc, item) => {
        if (!acc[item.organization_type]) {
          acc[item.organization_type] = [];
        }
        acc[item.organization_type].push(item);
        return acc;
      }, {});
    };
  
    const categorizedData = splitDataByType(data1);
  
    // console.log("categorizedData", categorizedData);
  
    setCorporate(categorizedData.corporate || []);
    setCircle(categorizedData.circle || []);
    setDivision(categorizedData.division || []);
    setSubdivision(categorizedData.subdivision || []);
  }, [data1,refresh]);
  
  useEffect(() => {
    if (!data1 || !selectedData.label || !selectedData.name) return;
  
    const organization = data1.find(item => 
      item.organization_name === selectedData.label &&
      item.organization_type.toLowerCase() === selectedData.name.toLowerCase()
    );
    if (!organization) return;
  
    const organizationId = organization.organization_id.toString();
    setOrganizationId(organizationId);

    const filterByOrgIdAndType = (prefixLength, type) => {
      return data1.filter(item => 
        item.organization_id.startsWith(organizationId.slice(0, prefixLength)) && 
        item.organization_type === type
      );
    };
  
    if (selectedData.name === "Corporate") {
      setCorporate(filterByOrgIdAndType(10, "corporate"));
      setCircle(filterByOrgIdAndType(3, "circle"));
      setDivision(filterByOrgIdAndType(3, "division"));
      setSubdivision(filterByOrgIdAndType(3, "subdivision"));
    } else if (selectedData.name === "Circle") {
      setCorporate(filterByOrgIdAndType(3, "corporate"));
      setCircle(filterByOrgIdAndType(10, "circle"));
      setDivision(filterByOrgIdAndType(4, "division"));
      setSubdivision(filterByOrgIdAndType(4, "subdivision"));
    } else if (selectedData.name === "Division") {
      setCorporate(filterByOrgIdAndType(3, "corporate"));
      setCircle(filterByOrgIdAndType(4, "circle"));
      setDivision(filterByOrgIdAndType(10, "division"));
      setSubdivision(filterByOrgIdAndType(7, "subdivision"));
    } else if (selectedData.name === "Subdivision") {
      setCorporate(filterByOrgIdAndType(3, "corporate"));
      setCircle(filterByOrgIdAndType(4, "circle"));
      setDivision(filterByOrgIdAndType(7, "division"));
      setSubdivision(filterByOrgIdAndType(10, "subdivision"));
    }


  
  }, [selectedData, data1,refresh]);

  // useEffect(()=>{

  //   if(selectedData){
  //     CONST  co
      
  //   }

  // },[selectedData])
  
  const corporateLabels = corporate.map(item => item.organization_name);
  const circleLabels = circle.map(item => item.organization_name);
  const divisionLabels = division.map(item => item.organization_name);
  const subdivisionLabels = subdivision.map(item => item.organization_name);


//   const MAX_LABEL_WIDTH = 50; // Set your max label width limit

// // Helper function to estimate the width of the label
// const getLabelWidth = (label, fontSize = 12) => {
//   const canvas = document.createElement('canvas');
//   const context = canvas.getContext('2d');
//   context.font = `${fontSize}px Arial`; // Use the same font as in the chart
//   return context.measureText(label).width;
// };

const data = {
  datasets: [


  
    {
      label: 'Subdivision',
      data: subdivisionLabels.map(() => 1), // Keep the data mapping as is
      backgroundColor: subdivisionLabels.map(() => 'rgba(153, 102, 255, 0.2)'),
      borderColor: subdivisionLabels.map(() => 'rgba(153, 102, 255, 1)'),
      borderWidth: 1,
      cutout: '30%',
      // Conditionally apply labels based on selected.name
      labels: selectedData.name === 'Division' || selectedData.name === 'Subdivision' || selectedData.name == 'Circle'
        ? subdivisionLabels // Show divisionLabels if selected.name is 'division'
        : subdivisionLabels.map(() => '-'), // Show '-' if selected.name is not 'division'
    },
    
    {
      label: 'Division',
      data: divisionLabels.map(() => 1),
      backgroundColor: divisionLabels.map(() => 'rgba(75, 192, 192, 0.2)'),
      borderColor: divisionLabels.map(() => 'rgba(75, 192, 192, 1)'),
      borderWidth: 1,
      cutout: '20%',
      labels: divisionLabels, // Assign specific labels for this dataset
    },
    {
      label: 'Circle',
      data: circleLabels.map(() => 1),
      backgroundColor: circleLabels.map(() => 'rgba(54, 162, 235, 0.2)'),
      borderColor: circleLabels.map(() => 'rgba(54, 162, 235, 1)'),
      borderWidth: 1,
      cutout: '15%',
      labels: circleLabels, // Assign specific labels for this dataset
    },
    {
      label: 'Corporate',
      data: corporateLabels.map(() => 1),
      backgroundColor: corporateLabels.map(() => 'rgba(255, 99, 132, 0.2)'),
      borderColor: corporateLabels.map(() => 'rgba(255, 99, 132, 1)'),
      borderWidth: 1,
      cutout: '10%',
      labels: corporateLabels, // Assign specific labels for this dataset
    },
  ],
};



// const data = {
//   datasets: [
//     {
//       label: 'Subdivision',
//       data: subdivisionLabels.map(() => 1),
//       backgroundColor: subdivisionLabels.map(() => 'rgba(153, 102, 255, 0.2)'),
//       borderColor: subdivisionLabels.map(() => 'rgba(153, 102, 255, 1)'),
//       borderWidth: 1,
//       cutout: '30%',
//       labels: selectedData.name === 'Division' || selectedData.name === 'Subdivision'
//         ? subdivisionLabels.map(label => (getLabelWidth(label) <= MAX_LABEL_WIDTH ? label : '-'))
//         : subdivisionLabels.map(() => '-'),
//     },
//     {
//       label: 'Division',
//       data: divisionLabels.map(() => 1),
//       backgroundColor: divisionLabels.map(() => 'rgba(75, 192, 192, 0.2)'),
//       borderColor: divisionLabels.map(() => 'rgba(75, 192, 192, 1)'),
//       borderWidth: 1,
//       cutout: '20%',
//       labels: divisionLabels.map(label => (getLabelWidth(label) <= MAX_LABEL_WIDTH ? label : '-')),
//     },
//     {
//       label: 'Circle',
//       data: circleLabels.map(() => 1),
//       backgroundColor: circleLabels.map(() => 'rgba(54, 162, 235, 0.2)'),
//       borderColor: circleLabels.map(() => 'rgba(54, 162, 235, 1)'),
//       borderWidth: 1,
//       cutout: '15%',
//       labels: circleLabels.map(label => (getLabelWidth(label) <= MAX_LABEL_WIDTH ? label : '-')),
//     },
//     {
//       label: 'Corporate',
//       data: corporateLabels.map(() => 1),
//       backgroundColor: corporateLabels.map(() => 'rgba(255, 99, 132, 0.2)'),
//       borderColor: corporateLabels.map(() => 'rgba(255, 99, 132, 1)'),
//       borderWidth: 1,
//       cutout: '10%',
//       labels: corporateLabels.map(label => (getLabelWidth(label) <= MAX_LABEL_WIDTH ? label : '-')),
//     },
//   ],
// };

  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const layer = context.dataset.label;
            const name = context.dataset.labels[context.dataIndex];
  
            return `${layer} - ${name}: ${context.raw}`;
          },
        },
      },
      datalabels: {
        color: '#000',
        display: true,
        formatter: (value, context) => {
          return context.dataset.labels[context.dataIndex] || '';
        },
        anchor: 'center',
        align: 'center',
        font: {
          weight: 'italic',
        },
        offset: 10,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const element = elements[0];
        const datasetIndex = element.datasetIndex;
        const index = element.index;
  
        const selectedLabel = data.datasets[datasetIndex].labels[index];
        const datasetLabel = data.datasets[datasetIndex].label;
  
        // Log the filtered value
        console.log('Filtered Value:', {
          name: datasetLabel,
          label: selectedLabel,
        });
  
        // Update state with the selected data (filtered value)
        setSelectedData({
          name: datasetLabel,
          label: selectedLabel,
        });
      }
    },
  };
  

  


  

  // if (!data1) return <div>Loading...</div>;\


  
  if (loading) {
    return (
      <div className="w-100">
      <div className="d-flex justify-content-end">
        {/* <button className="btn btn-danger m-2" onClick={handleRefresh}>Clear</button> */}
        <Skeleton Square={true} height={40} width={80} />
      </div>
      <div
        style={{
          width: '100%',
          height: '550px',
          margin: 0,
          padding: '0px 100px',
          boxSizing: 'border-box',
        }}
        className="d-flex justify-content-center align-items-center"
      >
                <Skeleton circle={true} height={300} width={300} />

      </div>
    </div>
    
    );
  }

  if (error) {
    return (
    <div
    style={{
      width: '100%',
      height: '550px',
      margin: 0,
      padding: '0px 100px',
      boxSizing: 'border-box',
    }}
    className="d-flex justify-content-center align-items-center"
  >
    <h5>Data not found</h5></div>
    )
  }

  return (
    <div className="w-100">
    <div className="d-flex justify-content-end  w-100 "><button className="btn btn-danger my-2 " onClick={handleRefresh}>Clear</button></div>
    <div
      style={{
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent:'center',
        // alignItems:'center',
        width: '100%',
        height: '550px',
        margin: 0,
        padding: '0px 0px',
        boxSizing: 'border-box',
      }}

      className="d-flex justify-content-center align-items-center"
    >
      
      <Doughnut data={data} options={options} />
      {/* <h3>Concentric Circle Chart</h3> */}
      
    </div>
    </div>
  );
};

export default OtherComponent;


