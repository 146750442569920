import React, { useEffect, useState } from 'react';
import LeftSidebar from '../Sidebar/LeftSideBar';
import { Button, hexToRgb, Typography } from '@mui/material';
import { Doughnut, Pie } from 'react-chartjs-2';
import axios from 'axios';
import { Chart as ChartJS, Title, Tooltip, Legend, PolarAreaController, RadialLinearScale, ArcElement } from 'chart.js';
import GraphModal from './GraphModal/GraphModal';
import OtherComponent from './OtherComponent';
import { PolarArea } from 'react-chartjs-2';
import ToggleSwitch from './ToggleSwitch/ToggleSwitch';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import * as XLSX from "xlsx";



// const url = 'https://api-reports.fornaxenergytech.com';
// const url = 'http://fornax:5000';
const url = process.env.REACT_APP_API_URL;


// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, PolarAreaController, RadialLinearScale, ArcElement);

const OrganizationView = () => {
  const [pointTypeData, setPointTypeData] = useState([]);
  const [organizationData, setOrganizationData] = useState();
  const [organizationId, setOrganizationId] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [selectedName, setSelectedName] = useState();
  const [selectedData, setSelectedData] = useState({ name: null, label: null });
  const [refresh, setRefresh] = useState(false);

  // const [isOn, setIsOn] = useState(false);
  const[project,setProjects] = useState([]);


  const [isConsumerLoad, setIsConsumerLoad] = useState(false);
  const [isSolarNumber, setIsSolarNumber] = useState(true);


  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [data, setData] = useState([]);

  // useEffect(() => {
  //     axios.get(`${url}/api/pointTypeSeprateData`)
  //         .then((response) => {
  //             setPointTypeData(response.data);
  //             console.log(" setPointTypeData", response.data);
  //         })
  //         .catch((error) => {
  //             console.error("Error fetching data:", error);
  //         });
  // }, []);
  // Fetch the data

  const handleRefresh = () => {
    console.log("handle refresh data", data);
    if (data) {  // Check if data is available
      setFilteredData(data);
      setSelectedData({ name: null, label: null });
      setRefresh(!refresh);

    } else {
      console.error("Data is not available yet.");
    }
  }

  useEffect(() => {
    let timeoutId;

    const fetchData = async () => {
      try {
        const result = await axios.get(`${url}/api/OrganizationView/Data`);
        console.log("newData", result.data);
        setData(result.data);
        setFilteredData(result.data);
        setDataLoaded(true);

        const filter = result.data.filter(item => item.point_type).map(item => item.point_type);
        console.error([...new Set(filter)]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    timeoutId = setTimeout(() => {
      if (!dataLoaded) {
        setLoading(false);
      }
    }, 5000);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
  }, [dataLoaded,url]);

  //   // const[lengthData, length]
  const [consumerDetail, setConsumerDetail] = useState([]);

  useEffect(() => {

    axios.get(`${url}/api/OrganizationView/ConsumersData`)
      .then((result) => {
        console.error("newData21", result.data);
        setConsumerDetail(result.data);
        // setData(result.data);
        // setFilteredData(result.data);
      }).catch((e) => {
        console.log("Error for Consumer Details :", e);
      });


  }, []);

  useEffect(() => {
    if (data.length > 0 && organizationId) {
      let filtered = [];

      const prefixMap = {
        "Corporate": organizationId.substring(0, 2),
        "Circle": organizationId.substring(0, 4),
        "Division": organizationId.substring(0, 7),
        "Subdivision": organizationId.substring(0, 10),
      };

      const prefix = prefixMap[selectedData.name];

      if (prefix) {
        filtered = data.filter(item => item.owner_id.startsWith(prefix));
      }

      setFilteredData(filtered);
      console.log("Organization Id for Filter Data:", organizationId);
      console.log("Filtered Data:", filtered);
    }
  }, [data, organizationId, selectedData]);

  useEffect(() => {
    if (refresh) {
      setFilteredData(data);
    }
  }, [refresh, data]);


  const [filterProjectIds, setFilterProjectIds] = useState([]);


  // Process the filtered data for point types
  useEffect(() => {
    if (filteredData.length === 0) {
      setPointTypeData([]);
      return;
    }
  
    // Function to count point types and calculate xmr_capacities
    const countPointTypes = (data) => {
      const result = data.reduce((acc, item) => {
        const pointType = item.point_type;
    
        if (!acc[pointType]) {
          acc[pointType] = {
            pointtype: pointType,
            size: 0,
            xmr_capacities: {}
          };
        }
    
        acc[pointType].size += 1;
    
        // Parse point_props to update xmr_capacities
        if (item.point_props) {
          try {
            const pointProps = JSON.parse(item.point_props);
            if (pointProps.xmr_capacity !== undefined) {
              const capacity = pointProps.xmr_capacity;
              acc[pointType].xmr_capacities[capacity] = (acc[pointType].xmr_capacities[capacity] || 0) + 1;
            }
          } catch (e) {
            console.warn('Error parsing point_props:', e);
          }
        }
    
        return acc;
      }, {});
    
      // Process and sort the result
      return Object.values(result)
        .map(item => ({
          pointtype: item.pointtype === "Gentry" ? "Feeders" : item.pointtype,
          size: item.size,
          xmr_capacities: Object.entries(item.xmr_capacities).map(([capacity, count]) => ({
            [capacity]:
            count
          }))
        }))
        .sort((a, b) => a.pointtype === "Feeders" ? -1 : b.pointtype === "Feeders" ? 1 : 0); // Feeders will be first
    };
    
    
  
    // Function to get unique positions from line_props
    const getUniquePositions = () => {
      return [
        ...new Set(
          filteredData.map(item => {
            try {
              const lineProps = JSON.parse(item.line_props);
              return lineProps?.position || null;
            } catch (e) {
              console.warn('Error parsing line_props:', e);
              return null;
            }
          }).filter(position => position !== null)
        )
      ];
    };
  
    // Function to calculate the length based on unique positions
    const calculateLength = (positions) => {
      return positions.map(position => {
        const lengthData = filteredData.filter(data => {
          try {
            const lineProps = JSON.parse(data.line_props);
            return lineProps?.position === position;
          } catch (e) {
            return false;
          }
        });
        const totalLength = lengthData.reduce((sum, data) => sum + (data.length || 0), 0);
        return { [position]: (totalLength / 1000).toFixed(2) }; // Convert to KM
      });
    };
  
    // Handle selectedName logic and graph data updates
    if (selectedName === "Consumers (NOS)" && isConsumerLoad) {
      setSelectedName("Load (KW)");
    } else if (selectedName === "Load (KW)" && !isConsumerLoad) {
      setSelectedName("Consumers (NOS)");
    } else if (selectedName === "PV (KW)" && isSolarNumber) {
      setSelectedName("PV Cons (NOS)");
    } else if (selectedName === "PV Cons (NOS)" && !isSolarNumber) {
      setSelectedName("PV (KW)");
    } 
  
    // Process graph data based on pointTypeData and selectedName
    const processGraphData = (pointTypeData) => {
      const filteredGraphData = pointTypeData.find(item => item.pointtype === selectedName);
      if (filteredGraphData && filteredGraphData.xmr_capacities) {
        setGraphData(getChartData(filteredGraphData.xmr_capacities));
      } else if (!selectedName) {
        const fallbackGraphData = pointTypeData.find(item => item.pointtype === "Transformer");
        if (fallbackGraphData && fallbackGraphData.xmr_capacities) {
          setGraphData(getChartData(fallbackGraphData.xmr_capacities));
          setSelectedName("Transformer");
        }
      } else {
        setGraphData(getChartData([]));
      }
    };
  
    // Count point types and filter out unwanted point types
    const pointTypeCounts = countPointTypes(filteredData);
    const filteredPointTypeCounts = pointTypeCounts.filter(item =>
      item.pointtype !== "Existing Pole(Double Circuit)" &&
      item.pointtype !== "HT Pole" &&
      item.pointtype !== "HT Route Point"
    );
  
    // Merge RMU with RMU with TC data
    const rmu = filteredPointTypeCounts.find(item => item.pointtype === "RMU");
    const rmuWithTC = filteredPointTypeCounts.find(item => item.pointtype === "RMU with TC");
    const transformer = filteredPointTypeCounts.find(item => item.pointtype === "Transformer");
  
    if (rmuWithTC) {
      if (rmu) {
        rmu.size += rmuWithTC.size;
        rmu.xmr_capacities = [...rmu.xmr_capacities, ...rmuWithTC.xmr_capacities];
      }
      if (transformer) {
        transformer.size += rmuWithTC.size;
        transformer.xmr_capacities = [...transformer.xmr_capacities, ...rmuWithTC.xmr_capacities];
      }
      const rmuIndex = filteredPointTypeCounts.indexOf(rmuWithTC);
      if (rmuIndex !== -1) filteredPointTypeCounts.splice(rmuIndex, 1);
    }
  
    // Get length data
    const uniquePositions = getUniquePositions();
    const xmr_capacity = calculateLength(uniquePositions);
  
    // Add length data to point type counts
    const lengthData = {
      pointtype: "Length (KM)",
      size: (filteredData.reduce((sum, data) => sum + (data.length || 0), 0) / 1000).toFixed(2),
      xmr_capacities: xmr_capacity
    };
  console.error('Organizatiopn View Filter Data',filteredData);

  const filterGentry = filteredData.filter(item => item.point_type == "Gentry");
   
  
  console.error("COunmt",filterGentry);
  // Filter by project ID and prepare final point type data
    const filterProjectId = filterGentry.length > 0
      ? [...new Set(filterGentry.map(item => item.project_id))]
      : null;
    setFilterProjectIds(filterProjectId);
    console.error('Organizatiopn View Filter Data',filterProjectId);

    
    const filterFeeder = filterProjectId ? filterFeederByProjectId(filterProjectId) : [];
  
    console.log("filterFeeder",filterFeeder);
    const consumerLoad = filterProjectId ? filterLoadByProjectId(filterProjectId, consumerDetail) : [];
    const consumer = filterProjectId ? filterConsumersByProjectId(filterProjectId, consumerDetail) : [];
    const solarMeter = filterProjectId ? filterSolarMeterByProjectId(filterProjectId, consumerDetail) : [];
    const solarLoad = filterProjectId ? filterSolarLoadByProjectId(filterProjectId, consumerDetail) : [];
  
    console.error('Organizatiopn View Filter Consumer Data',consumer);


    
    // Final point type counts including length and consumer data
    let finalPointTypeCounts = [...filteredPointTypeCounts, lengthData];
  
    if (isConsumerLoad && isSolarNumber) {
      finalPointTypeCounts.push(consumerLoad, solarMeter);
    } else if (!isConsumerLoad && isSolarNumber) {
      finalPointTypeCounts.push(consumer, solarMeter);
    } else if (isConsumerLoad && !isSolarNumber) {
      finalPointTypeCounts.push(consumerLoad, solarLoad);
    } else {
      finalPointTypeCounts.push(consumer, solarLoad);
    }

    // const lengthConsole = finalPointTypeCounts.filter(item => item.cate) 

    

// Separate feeders and non-feeders
const getFeeder = finalPointTypeCounts.filter(item => item.pointtype === 'Feeders');
const newFinalPointtypecount = finalPointTypeCounts.filter(item => item.pointtype !== 'Feeders');


// Update the xmr_capacity for feeders
const updatedFeeders = getFeeder.map(feeder => {
  const updatedCapacity = filterFeederByProjectId([feeder.project_id]) || {};

  // Remove one item from xmr_capacity if it exists
  const modifiedCapacity = { ...updatedCapacity };
  const keys = Object.keys(modifiedCapacity);
  
  if (keys.length > 0) {
    const firstKey = keys[0];
    if (modifiedCapacity[firstKey] > 0) {
      modifiedCapacity[firstKey] -= 1; // Decrement the first category
    }
  }

  console.error("Keys",keys);
  console.log("ModifiedCapacity",updatedCapacity);

  return {
    ...feeder,
    xmr_capacities: filterFeeder
  };
});

console.log(updatedFeeders);

// Combine updated feeders with non-feeders
const finalPointtype = [ ...updatedFeeders, ...newFinalPointtypecount];

      console.log("pointtypeData", finalPointtype);
      // const[ ...rest]

         // Set point type data and process graph data
         setPointTypeData(finalPointTypeCounts);
         processGraphData(finalPointtype);
   

      setPointTypeData(finalPointtype);
    // // Set point type data and process graph data
    // setPointTypeData(finalPointTypeCounts);
    // processGraphData(finalPointTypeCounts);

    console.log(finalPointtype)
  
  }, [filteredData, isConsumerLoad, isSolarNumber, selectedName]);
  



  const filterFeederByProjectId = (filterProjectId) => {
    if (!project || typeof project !== 'object') {
      console.error("Invalid Feeder Detail:", project);
      return null;
    }
  console.log("project list",filterProjectId);

    const filteredProjects = project.filter(item => filterProjectId.includes(item.project_id));

 // Count occurrences by category
 const countByCategory = filteredProjects.reduce((acc, item) => {
  const category = item.category; // Replace 'category' with the actual property you want to count by
  acc[category] = (acc[category] || 0) + 1; // Adjust if necessary
  return acc;
}, {});

console.error("countByCategory",filteredData);
// Transform the countByCategory object into the desired output format
const result = Object.entries(countByCategory).map(([key, value], index) => ({
  [key]: value
}));

return result;
};


  const filterConsumersByProjectId = (projectids, consumerData) => {
    if (!consumerData || typeof consumerData !== 'object') {
      console.error("Invalid consumerData:", consumerData);
      return null;
    }

    let totalSize = 0;
    const totalXmrCapacities = {};

    projectids.forEach(projectid => {
      const filteredConsumers = consumerData[projectid];
      

      if (!filteredConsumers) {
        console.warn(`Project ID ${projectid} not found in consumerData`);
        return; // Skip this project ID if not found
      }

      // Accumulate the size
      totalSize += filteredConsumers.reduce((sum, item) => sum + item.consumers, 0);

      // Accumulate the xmr_capacities
      filteredConsumers.forEach(item => {
        if (!totalXmrCapacities[item.category]) {
          totalXmrCapacities[item.category] = 0;
        }
        totalXmrCapacities[item.category] += item.consumers;
      });
    });

    // Convert the totalXmrCapacities object to an array of objects
    const xmrCapacitiesArray = Object.entries(totalXmrCapacities).map(([category, consumer]) => ({
      [category]: consumer
    }));

    return {
      pointtype: "Consumers (NOS)",
      size: totalSize || 0,
      xmr_capacities: xmrCapacitiesArray || []
    };
  };



  const filterLoadByProjectId = (projectids, consumerData) => {
    if (!consumerData || typeof consumerData !== 'object') {
      console.error("Invalid consumerData:", consumerData);
      return null;
    }

    let totalSize = 0;
    const totalXmrCapacities = {};

    projectids.forEach(projectid => {
      const filteredConsumers = consumerData[projectid];

      if (!filteredConsumers) {
        console.warn(`Project ID ${projectid} not found in consumerData`);
        return; // Skip this project ID if not found
      }

      // Accumulate the size
      totalSize += filteredConsumers.reduce((sum, item) => sum + item.load, 0);

      // Accumulate the xmr_capacities
      filteredConsumers.forEach(item => {
        if (!totalXmrCapacities[item.category]) {
          totalXmrCapacities[item.category] = 0;
        }
        totalXmrCapacities[item.category] += parseInt(item.load);
      });
    });

    // Convert the totalXmrCapacities object to an array of objects
    const xmrCapacitiesArray = Object.entries(totalXmrCapacities).map(([category, consumer]) => ({
      [category]: consumer
    }));

    return {
      pointtype: "Load (KW)",
      size: parseInt(totalSize) || 0,
      xmr_capacities: xmrCapacitiesArray || []
    };
  };



  // const filterSolarMeterByProjectId = (projectids, consumerData) => {
  //   if (!consumerData || typeof consumerData !== 'object') {
  //     console.error("Invalid consumerData:", consumerData);
  //     return null;
  //   }

  //   let totalSize = 0;
  //   const totalXmrCapacities = {};

  //   projectids.forEach(projectid => {
  //     const filteredConsumers = consumerData[projectid];

  //     if (!filteredConsumers) {
  //       console.warn(`Project ID ${projectid} not found in consumerData`);
  //       return; // Skip this project ID if not found
  //     }

  //     // Accumulate the size
  //     totalSize += filteredConsumers.reduce((sum, item) => sum + item.consumers, 0);

  //     // Accumulate the xmr_capacities
  //     filteredConsumers.forEach(item => {
  //       if (!totalXmrCapacities[item.category]) {
  //         totalXmrCapacities[item.category] = 0;
  //       }
  //       totalXmrCapacities[item.category] += item.consumers;
  //     });
  //   });

  //   // Convert the totalXmrCapacities object to an array of objects
  //   const xmrCapacitiesArray = Object.entries(totalXmrCapacities).map(([category, consumer]) => {
  //     // Ensure consumer is a number before formatting
  //     const formattedConsumer = parseInt(consumer)
  //     return {
  //       [category]: formattedConsumer
  //     };
  //   });
  //   return {
  //     pointtype: "PV Cons (NOS)",
  //     size: totalSize || 0,
  //     xmr_capacities: xmrCapacitiesArray || []
  //   };
  // };



  const filterSolarMeterByProjectId = (projectids, consumerData) => {
    if (!consumerData || typeof consumerData !== 'object') {
      console.error("Invalid consumerData:", consumerData);
      return null;
    }
  
    let countSize = 0; // Initialize count for items with consumers > 0
    const countXmrCapacities = {}; // Initialize an object to count items by category
  
    // Process each project ID
    projectids.forEach(projectid => {
      const filteredConsumers = consumerData[projectid];
  
      if (!filteredConsumers) {
        console.warn(`Project ID ${projectid} not found in consumerData`);
        return; // Skip this project ID if not found
      }
  
      // Count items with consumers > 0
      filteredConsumers.forEach(item => {
        if (item.consumers !== 0) {
          countSize++;
  
          // Increment the count of items by category
          if (!countXmrCapacities[item.category]) {
            countXmrCapacities[item.category] = 0;
          }
          countXmrCapacities[item.category]++;
        }
      });
    });
  
    // Convert the countXmrCapacities object to an array of objects
    const xmrCapacitiesArray = Object.entries(countXmrCapacities).map(([category, count]) => {
      return {
        [category]: count
      };
    });
  
    return {
      pointtype: "PV Cons (NOS)",
      size: countSize || 0,
      xmr_capacities: xmrCapacitiesArray || []
    };
  };

  const filterSolarLoadByProjectId = (projectids, consumerData) => {
    if (!consumerData || typeof consumerData !== 'object') {
      console.error("Invalid consumerData:", consumerData);
      return null;
    }

    let totalSize = 0;
    const totalXmrCapacities = {};

    projectids.forEach(projectid => {
      const filteredConsumers = consumerData[projectid];

      if (!filteredConsumers) {
        console.warn(`Project ID ${projectid} not found in consumerData`);
        return; // Skip this project ID if not found
      }

      // Accumulate the size
      totalSize += parseInt( filteredConsumers.reduce((sum, item) => sum + item.solar_load, 0));

      // Accumulate the xmr_capacities
      filteredConsumers.forEach(item => {
        if (!totalXmrCapacities[item.category]) {
          totalXmrCapacities[item.category] = 0;
        }
        totalXmrCapacities[item.category] += item.solar_load;
      });
    });

    // Convert the totalXmrCapacities object to an array of objects
    const xmrCapacitiesArray = Object.entries(totalXmrCapacities).map(([category, consumer]) => ({
      [category]: parseInt(consumer)
    }));

    return {
      pointtype: "PV (KW)",
      size: totalSize || 0,
      xmr_capacities: xmrCapacitiesArray || []
    };
  };




  // Prepare chart data based on fetched data
  const getChartData = (capacities) => {
    const labels = [];
    const values = [];
    const colors = ['rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)'];

    capacities.forEach((capacityObj) => {
      const [key, value] = Object.entries(capacityObj)[0];
      labels.push(key);
      values.push(value);
      // Generate a color for each data point
      colors.push(`rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`);
    });

    return {
      labels,
      datasets: [
        {
          label: 'XMR Capacities',
          data: values,
          backgroundColor: colors,
          borderColor: colors.map(color => color.replace('0.2', '1')), // Use same color but with full opacity for border
          borderWidth: 1,

        },
      ],
    };
  };

  const options = {
    responsive: true,
    layout: {
      padding: {
        left: 50, // Adjust this value to create space between the graph and the legend
      },
      justifyContent: 'space-between'
    },
    plugins: {
      legend: {
        paddingLeft: '50px',
        position: 'right',
        align: 'center'
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.label + ': ' + context.raw;
          },
        },
      },
    },
  };

  const [graphData, setGraphData] = useState(null);
  useEffect(() => {
    // Fetch data from API
    axios.get(`${url}/api/organization/Data`)
      .then((response) => {
        // Process and set the data
        const fetchedData = response.data;
        const labels = Object.keys(fetchedData);
        const values = Object.values(fetchedData);

        setOrganizationData({
          labels,
          datasets: [
            {
              label: 'Organization Types',
              data: values,
              backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)'],
              borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
              borderWidth: 1,
            },
          ],
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Chart options
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw}`;
          },
        },
      },
    },
  };

  // useEffect(()=>{

  //   if(isConsumerLoad){
  //     setSelectedName("Consumers");

  //   }else{
  //     setSelectedName("Load");
  //   }

  // },[isConsumerLoad]);


  // useEffect(()=>{

  //   if(isConsumerLoad){
  //     setSelectedName("Solar Meters");
  //   }else{
  //     setSelectedName("Solar Load");
  //   }

  // },[isConsumerLoad]);


  const handleClick = (capacities, pointtype) => {
    // console.log("handle click", capacities, ": pointtype  :", pointtype)
    // setGraphData(getChartData(capacities));
    setSelectedName(pointtype);
  };

  // console.log('Organization Data', organizationData)

  // useEffect(() => {
  //   // Find the first item with pointtype "Transformer"
  //   const transformerData = pointTypeData.find(point => point.pointtype === "Transformer");
  // handleClick(transformerData.xmr_capacities, transformerData.pointtype);
  //   if (transformerData) {
  //    
  //     setGraphData(getChartData(transformerData.xmr_capacities));
  //   }
  // }, []); // Depend on pointTypeData to trigger when it changes




  const exportExcel = () => {
    // Prepare new data excluding xmr_capacities
    const newData = pointTypeData.map(({ xmr_capacities, ...rest }) => rest);
  
    // Filter and map xmr_capacities based on selectedName or default to "Transformer"
    const xmrCapacities = selectedName
      ? pointTypeData
          .filter((item) => item.xmr_capacities && item.pointtype === selectedName)
          .map((item) => item.xmr_capacities)
      : pointTypeData
          .filter((item) => item.xmr_capacities && item.pointtype === "Transformer")
          .map((item) => item.xmr_capacities);
  
          const flattenedCapacities = xmrCapacities.flatMap((capacity) =>
            Object.entries(capacity).flatMap(([outerKey, innerObject]) =>
              Object.entries(innerObject).map(([innerKey, innerValue]) => ({
                 
                "key":innerKey,    // The key from the inner object
                "Value":innerValue   // The value from the inner object
              }))
            )
          );
    console.log("xmrCapacities", flattenedCapacities);
  
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Convert pointTypeData to the first sheet
    const worksheet1 = XLSX.utils.json_to_sheet(pointTypeData);
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Point Type Tab;e");
  
    const worksheet2 = XLSX.utils.json_to_sheet(flattenedCapacities);
    XLSX.utils.book_append_sheet(workbook, worksheet2,  `${selectedName}_xmr_capacities`);
  
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, "MyExcelFile.xlsx");
  };
  





  console.log("Graph Data check", graphData);
  console.log("pointtype Data check", pointTypeData);


  return (
    <>
      <div className="w-100 dashboard-body " style={{ height: '100%', maxHeight: '100%' }}>
        {/* <LeftSidebar /> */}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', height: '50px', background: 'white' }} className="border p-2">
          <Typography variant="h6" gutterBottom sx={{ padding: '10px' }}>
            Organization View
          </Typography>
          <Button variant="outlined" onClick={exportExcel}>Export</Button>
        </div>
        <div className='' style={{ overflowY: 'scroll', height: '100vh', transition: '.5s ease' }}  >

          {/* <div className="head   m-3"style={{ fontFamily: 'Montserrat, sans-serif', textAlign:'left' }}><h4>Data View</h4></div> */}
          <div className=" m-2 shadow  rounded bg-white">
            <div style={{ overflowX: 'auto', borderRadius: '10px' }}>
              {/* {pointTypeData.length === 0 ? */}
              <table className="table table-bordered w-100 h-100"  >

              {loading ? (

                  <table className="table table-bordered w-100 h-100">
                  <thead>
                    <tr>
                      {Array.from({ length: 10 }).map((_, index) => (
                        <th key={index}>
                          <Skeleton width={80} height={30} />
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: 2 }).map((_, rowIndex) => (
                      <tr key={rowIndex}>
                        {Array.from({ length: 10 }).map((_, colIndex) => (
                          <td key={colIndex}>
                            <Skeleton width={80} height={25} />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
              </table>

          ) : 
                pointTypeData.length > 0 ? (

                  <tbody>
                    <tr>
                      <th>Object Type</th>
                      {pointTypeData.map((item, index) => (
                        <th key={index}>{item.pointtype}</th>
                      ))}
                    </tr>
                    <tr>
                      <td>Numbers</td>
                      {pointTypeData.map((item, index) => (
                        <td key={index}>{item.size}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Data View</td>
                      {pointTypeData.map((item, index) => (
                        <td key={index}>

                          {item.xmr_capacities.length > 0 ? <button className="btn btn-primary" onClick={() => handleClick(item.xmr_capacities, item.pointtype)}>View </button> : <button className="btn btn-secondary disabled" onClick={() => handleClick(item.xmr_capacities, item.pointtype)}>View </button>}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                ) : (<div className="d-flex justify-content-center align-items-center w-100" style={{ width: "100vw", height: '150px' }}><h5 className="">No Data  Found</h5></div>)}
              </table>
              {/* : <h1>No Data Found</h1>} */}
            </div>
          </div>

          {/* <div className="graph-container d-flex flex-wrap flex-row-reverse justify-content-center" >
                    
              
                    <div className="bg-white shadow rounded w-50" >
                  {graphData && pointTypeData.length > 0 ? (
                        <div style={{  height: '100%', width: '100%' }}>
                 
           
                    <div  className='d-flex justify-content-center' style={{ height: '600px',paddingTop:'50px'}}>
    
                            <PolarArea data={graphData} options={options} /> </div>
                        </div>
                    ) : ( <div className="d-flex justify-content-center align-items-center w-100 h-100" ><h5 className="">No Data  Found</h5></div> )}
                    </div>
                        <div className='bg-white p-2 mx-2 shadow rounded d-flex justify-content-center w-50' >
                          
                                                 <OtherComponent setOrganizationId={setOrganizationId} handleRefresh={handleRefresh} selectedData={selectedData} setSelectedData={setSelectedData} refresh={refresh}/>
                        </div>
                        </div>


            
             

            </div>

            </div> */}




          <div className=" d-flex flex-row-reverse justify-content-between " >

            {/* <GraphModal data={getChartData(item.xmr_capacities)} options={options} /> */}

            <div className="bg-white w-50 shadow rounded">
            {loading ? (
              <div>
               <div className="d-flex justify-content-end m-3">
        < Skeleton square={true} height={50} width={100} />

              
             </div>
                  <div className='d-flex justify-content-center' style={{ height: '600px', paddingTop: '50px' }}>

< Skeleton circle={true} height={300} width={300} />

                </div>
                </div>

          ) : pointTypeData.length > 0 && graphData ? (
                <div style={{ height: '100%', width: '100%' }}>
                  <div className="d-flex justify-content-between m-3">
                    <h3>{selectedName}</h3>
                    {(selectedName === "Consumers (NOS)" || selectedName === "Load (KW)") && (
                      <ToggleSwitch isOn={isConsumerLoad} setIsOn={setIsConsumerLoad} data={["Consumers (NOS)", "Load (KW)"]} />
                    )}

                    {(selectedName === "PV Cons (NOS)" || selectedName === "PV (KW)") && (
                      <ToggleSwitch isOn={isSolarNumber} setIsOn={setIsSolarNumber} data={["PV (KW)", "PV Cons (NOS)"]} />
                    )}

                  </div>

                  <div className='d-flex justify-content-center' style={{ height: '600px', paddingTop: '50px' }}>

                    {/* <PolarArea data={graphData} options={options} /> </div> */}
                    <PolarArea data={graphData} options={options} />
                  </div>


                </div>
              ) : (<div className="d-flex justify-content-center align-items-center w-100 h-100" ><h5 className="">No Data  Found</h5></div>)}
            </div>
            <div className='bg-white w-50 mx-2 p-2 shadow rounded d-flex justify-content-center'>

              {/* <SubstationDoghtnut setOrganizationId={setOrganizationId} handleRefresh={handleRefresh} selectedData={selectedData} setSelectedData={setSelectedData} refresh={refresh}/> */}
              <OtherComponent setOrganizationId={setOrganizationId} project={project} setProjects={setProjects}  handleRefresh={handleRefresh} selectedData={selectedData} setSelectedData={setSelectedData} refresh={refresh} />

            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default OrganizationView;
