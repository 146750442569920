import React from 'react';
import { Treemap, Tooltip } from 'recharts';

const COLORS = ['#FFBB28', '#00C49F', '#FF8042', '#8884D8'];

const CustomizedContent = (props) => {
  const { root, depth, x, y, width, height, index, colors, name, size } = props;
  
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: colors[index % colors.length],
          stroke: '#fff',
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />
      {width > 80 && height > 20 && (
        <text
          x={x + width / 2}
          y={y + height / 2}
          textAnchor="middle"
          fill="#000" // Black text color
            stroke="#000"
          fontSize={14}
          fontWeight="bold"
        >
          {`${name}: ${size}`}
        </text>
      )}
    </g>
  );
};

const TabbedViewer = ({ data }) => {
  // console.log(data);
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  

  const transformData = (data) => {
    return data.map(item => {
      // Get the first key (assuming the first key is the one to use for labels)
      const keyName = Object.keys(item)[0];
      const keyname2 = Object.keys(item)[1];
      const keyValue = item[keyName];
  
      return {
        name: `${keyName}: ${keyValue}`, // Create a label with the key name and value
        size: item[keyname2], // Set the size to count (assumes each item has a 'size' key)
      };
    });
  };
  

  const data1 = transformData(data);
  return (
    <div className="d-flex justify-content-center m-2">
    <Treemap
      width={500}
      height={180}
      data={data1}
      dataKey="size"
      ratio={4 / 3}
      stroke="#fff"
      fill="#8884d8"
      content={<CustomizedContent colors={COLORS} />}
    >
      <Tooltip />
    </Treemap>
    </div>
  );
};

export default TabbedViewer;
