import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
} from '@mui/material';

const SelectDialog = ({selectedColumns,columnwiseFilter}) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const[valueOption, setValueOption] = useState('');
  const [searchText, setSearchText] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleValueChange = (event) =>{
    setValueOption(event.target.value);
    
  }
  const handleSearch = () => {
    // console.log(`Search for: ${searchText} with filter: ${selectedOption}`);
    // Add your search logic here
    columnwiseFilter(selectedOption,valueOption);
    handleClose();
  };

  
// console.log("Columns",selectedColumns); 
  return (
    <div>
      <Button variant="outlined" className="m-2" onClick={handleClickOpen}
      
      style={{
        margin: '10px 8px 10px 0',
        height: '3.5rem',
        borderColor: '#007BFF',
        color: '#007BFF',
        fontWeight: 'bold',
        fontSize: '1rem',
        borderRadius: '8px',
      }}
      
      
      >
        Columns Sum
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Columns For Sum</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="select-label">Select Column</InputLabel>
              <Select
                labelId="select-label"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                {selectedColumns.length > 0 && selectedColumns.map((column) => (

<MenuItem value={column}>{column}</MenuItem>


                ))}
              
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="select-label">Values Column</InputLabel>
              <Select
                labelId="select-label"
                value={valueOption}
                onChange={handleValueChange}
              >
                   {selectedColumns.length > 0 && selectedColumns.map((column) => (

<MenuItem value={column}>{column}</MenuItem>


                ))}
          
              </Select>
            </FormControl>
           
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectDialog;
