// Sidebar.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import "./css/Sidebar.css";
// import { FaSyncAlt } from 'react-icons/fa'; // Import refresh icon from FontAwesome
import axios from "axios";
import DataTable from 'react-data-table-component';
// import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import FilterModal from './../main/FilterModal';
import { ElevatorSharp } from "@mui/icons-material";


const baseUrl = "https://geoserver.fornaxenergytech.com";


const LeftSidebar = ({
 columns,selectedColumns,handleColumnChange,handleShowData,handleAverage,handleSum,columnwiseFilter,menuSection,setMenuSection,setSubMenuSection,subMenuSection,handleClearReport,isData
   
}) => {

  const leftSubmenuRef = useRef(null);

//  console.log(isData)

  // const [subMenuSection, setSubMenuSection] = useState(null);
  const [toggleStates, setToggleStates] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(0);
  const [options, setOptions] = useState([]);

  const [error, setError] = useState('Select Transformer or Line Detail');

  // Function to handle toggle switch change
  const handleToggleChange = (index) => {
    const newToggleStates = [...toggleStates];
    newToggleStates[index] = !newToggleStates[index];
    setToggleStates(newToggleStates);
  };

  // Function to add a new toggle switch
  const addToggleSwitch = () => {
    setToggleStates([...toggleStates, false]);
  };

 
  

  const toggleAccordion = (accordionNumber) => {
    // console.log("Accordation number  -> ", accordionNumber);
    setOpenAccordion(
      openAccordion === accordionNumber ? null : accordionNumber
    );
  };

  const removeClass = () => {
    if (leftSubmenuRef.current) {
      leftSubmenuRef.current.classList.remove("visible");
      setMenuSection(null);
      
    }
  };

  // const openLeftSubmenu = () => {
  //   if (!isClassAdded) {
  //     setIsClassAdded(true);
  //   }
  // };

  const openLeftSubmenu = (section) => {
    if (menuSection === section) {
      setMenuSection(null);
      
      // handleBaseLayerChange();

       } 
      else{
      setMenuSection(section);
     

    }
  };


  useEffect(() =>{
    if(menuSection == "reports"){
      setSubMenuSection("reports");
    }
  },[menuSection])

  // console.log("isData",isData)

  // console.error("Columnsw",columns);
  return (
    <div className="leftsidebar sidebar h-100">
      <div className="main-menu">
        <ul className="leftsidebar-menu">
         
         
        <li className={`${menuSection == "dashboard" ? "active" : ""}`}>
            <a href="#" onClick={() => openLeftSubmenu("dashboard")} >
              <img
                src="dashboardSVG.svg"
                className="sidebar-icon"
                alt="dashboard"
                title="Dashboard"
              />
              <span>Dashboard</span>
            </a>
          </li>
      
          <li className={`${menuSection == "reports" ? "active" : ""}`}>
            <a href="#" onClick={() => openLeftSubmenu("reports")} >
              <img
                src="report.svg"
                className="sidebar-icon"
                alt="reports"
                title="Reports"
              />
              <span>Report</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img
                src="help.svg"
                className="sidebar-icon"
                alt="help"
                title="Help"
              />
            </a>
          </li>
        </ul>
      </div>

      <div
        ref={leftSubmenuRef}
        className={`sub-sidebar-wrap sub-menu ${
          menuSection !== null ? "visible" : ""
        }`}
      style={{height:'100vh'}}
      >

 
        <ul
          data-parent="objects"
          className={`${menuSection == "reports" ? "d-block" : "d-none"}`}
        >
          <li>
            <h6 className="sub-menu-title mb-0">Report</h6>
          </li>   


          <li>
          {columns.map((table, index) => (
  <div className="acoordion-wrap" key={index}>
  <div
    className={`accordion ${
      openAccordion === index ? "open" : ""
    }`}
  >
    <div
      className="accordion-header"
      onClick={() => toggleAccordion(index)}
    >
      <h3>{ index === 0 
  ? "Organization Detail" :index === 1
  ? "Network Detail" 
  : index === 2
  ? "Transformer Detail" 
  :index === 3 ? "Line Detail" : "Consumer Detail" }</h3>
      
      
      <span
        className={`arrow ${
          openAccordion ===  index ? "open" : ""
        }`}
      > </span>
    </div>
    
   </div>
  
   {/* <div className="accordion-content"> */}
                      <ul>
                        
   {openAccordion === index && table.map(col => (
              <li key={col}>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedColumns.includes(col)}
                    onChange={() => handleColumnChange(col)}
                    className="mx-2"
                  />
                  {col.charAt(0).toUpperCase() + col.slice(1)}
                </label>
              </li>
            ))}
                        </ul>
                        </div>
                        // </div>   

        ))}
        
        </li>
        <li>
        <button
                className="load-map-btn mt-3 w-100"
                onClick={handleShowData}
                type="button"
                disabled={(selectedColumns.includes("Capacity - KVA") || selectedColumns.includes("Line Type")) == false}
              >
                Show Details
              </button>
        {/* {error && <p style={{ color: 'red', padding:'2px' }}>{error}</p>} */}

        </li>
    
          <li className={`${isData === 'true' ? "show" : "hide"}`}>
          <ul data-parent="print" className="d-block mt-5">
  <li>
    <h6 className="sub-menu-title mb-0">Calculate</h6>
  </li>
  <li>
    <Button
      variant="outlined"
      onClick={handleAverage}
      style={{
        margin: '10px 8px 10px 0',
        height: '3.5rem',
        borderColor: '#007BFF',
        color: '#007BFF',
        fontWeight: 'bold',
        fontSize: '1rem',
        borderRadius: '8px',
        width:'100px'
      }}
    >
      Average
    </Button>
    <Button
      variant="outlined"
      onClick={handleSum}
      style={{
        margin: '10px 8px 10px 0',
        height: '3.5rem',
        borderColor: '#007BFF',
        color: '#007BFF',
        fontWeight: 'bold',
        fontSize: '1rem',
        borderRadius: '8px',
        width:'100px'

      }}
    >
      Sum
    </Button>
  </li>
</ul>

<ul data-parent="print" className="d-block">
  <li>
    <h6 className="sub-menu-title mb-0">Filter & Summarize</h6>
  </li>
  <li>
    <FilterModal
      selectedColumns={selectedColumns}
      columnwiseFilter={columnwiseFilter}
    />
  </li>
</ul>

</li>

{/* )} */}
<li>  <button
                className="load-map-btn mt-3 w-100"
                onClick={handleClearReport}
                type="button"
                // disabled={(selectedColumns.includes("Capacity (KVA)") || selectedColumns.includes("Line Type")) == false}
              >
                Clear Report
              </button></li>
        </ul>

   
   
 
        <ul
          data-parent="objects"
          className={`${menuSection == "dashboard" ? "d-block" : "d-none"}`}
        >
          <li>
            <h6 className="sub-menu-title mb-0">Dashboard</h6>
          </li>  
          <ul>
                        
          <li className={`p-2 ${subMenuSection == "dashboard" ? "navlink" :""}`}>
                                     <a onClick={() => setSubMenuSection('dashboard')}>
                                     <label>
                                       
                                      Dashboard
                                     </label></a>
                                   </li>

                                   <li className={`p-2 ${subMenuSection == "organizationView" ? "navlink" :""}`}>
                                     <a onClick={() => setSubMenuSection('organizationView')}>
                                     <label>
                                       
                                       Organization View
                                     </label></a>
                                   </li>

                                   <li className={`p-2 ${subMenuSection == "substationView" ? "navlink" :""}`}>
                                     <a onClick={() => setSubMenuSection('substationView')}>
                                     <label>
                                       
                                       Substation View
                                     </label></a>
                                   </li>
                                             </ul>

           

          </ul>

     
        <div className="d-flex justify-content-center">
        <button className="close-submenu" onClick={removeClass}>
          <img src="left-double-chevron.png" alt="" />
        </button> 
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;