import React, { useState } from 'react';
import './ToggleSwitch.css'; // Make sure to create this CSS file for styling

const ToggleSwitch = ({isOn,setIsOn,data}) => {

    // Function to toggle the switch state
    const toggleSwitch = () => {
      setIsOn(prevIsOn => !prevIsOn);
    };

    // console.log("toggle",isOn);

  return (
    <div className="toggle-container">
    <span className="toggle-label">{isOn ? data[1] : data[0]}</span>
    <label className="switch">
      <input type="checkbox" checked={isOn} onChange={toggleSwitch} />
      <span className="slider"></span>
    </label>
  </div>
  );
};

export default ToggleSwitch;
