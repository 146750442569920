import React, { useEffect, useState, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, DoughnutController, RadialLinearScale } from 'chart.js';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

ChartJS.register(Title, Tooltip, Legend, ArcElement, DoughnutController, RadialLinearScale, ChartDataLabels);

const url =  process.env.REACT_APP_API_URL;
// console.log('API URL:', url); 

const SubstationDoghtnut = ({setOrganizationId,organizationId,handleRefresh,selectedData,setSelectedData,refresh,project, setProjects})  => {
  const [data1, setData1] = useState(null);

//   const [corporate, setCorporate] = useState([]);
//   const [circle, setCircle] = useState([]);
//   const [division, setDivision] = useState([]);
//   const [subdivision, setSubdivision] = useState([]);
// const[project,setProjects] = useState([]);
const[subStation,setSubStation] = useState([]);
const[filteredproject,setFilteredProject] = useState([]);
const[filteredSubStation,setFilteredSubStation] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(false);

useEffect(() => {
  const timeout = setTimeout(() => {
    setError(true);
    setLoading(false);
  }, 5000); // 5-second timeout for data fetch

  axios.get(`${url}/api/SubstanceView/Data`)
    .then((res) => {
      setSubStation(res.data.substation);
      setProjects(res.data.project);
      setFilteredSubStation(res.data.substation);
      setFilteredProject(res.data.project);
      setLoading(false);
      clearTimeout(timeout);
    })
    .catch((err) => {
      console.error('Error fetching data:', err);
      setError(true);
      setLoading(false);
    });

  return () => clearTimeout(timeout);
}, [refresh]);

  // const[refresh,setRefresh] = useState(false);


useEffect(()=>{



    console.error("Selected Data", selectedData);
    if(selectedData.type == "ss_name"){
        const filtered = subStation.filter(item => item.ss_name == selectedData.name);
        console.log("filter Data ", filtered[0].ss_id);
        const filterproject = project.filter(item => item.substation_id == filtered[0].ss_id);
        setFilteredProject(filterproject);
        setFilteredSubStation(filtered);
        setOrganizationId(filtered[0].owner_id);

        console.log("filter Data ", filteredproject);   
    }else if(selectedData.type == "category"){

        const filtered = project.filter(item => item.category == selectedData.name);
        const filterSubstation = subStation.filter(item => 
            filtered.some(filteredItem => filteredItem.substation_id === item.ss_id)
          );
        setOrganizationId(filtered[0].owner_id);
          
          setFilteredProject(filtered);
          setFilteredSubStation(filterSubstation);
        console.log("filter Data ", filterSubstation);
        

    }
    else if(selectedData.type == "project_name"){

      
      const filtered = project.filter(item => item.project_name.toLowerCase() === selectedData.name.toLowerCase());
      const filterSubstation = subStation.filter(item => 
          filtered.some(filteredItem => filteredItem.substation_id === item.ss_id)
        );
      setOrganizationId(filtered.owner_id);
        
        setFilteredProject(filtered);
        setFilteredSubStation(filterSubstation);
      // console.log("filter Data ", project);
      // console.log("filtered project_name ", filtered);
      


    }
},[selectedData])

  
  const voltageLabels = [...new Set(filteredSubStation.map(item => item.ss_voltage))];
  const substationnameLabels = filteredSubStation.map(item => item.ss_name);
  const projectTypeLabels =  [...new Set(filteredproject.map(item => item.category))];
  const projectnameLabels = filteredproject.map(item => item.project_name);

  // console.log("Corporate",voltageLabels);
  // console.log(",circleLabels",substationnameLabels);
  // console.log("divisionLabels",projectTypeLabels);
  // console.log("subdivisionLabels",projectnameLabels);

  const createDataset = (labels, backgroundColor, borderColor, cutout, label) => ({
    label: label,
    data: labels.map(() => 1),
    backgroundColor: labels.map(() => backgroundColor),
    borderColor: labels.map(() => borderColor),
    borderWidth: 1,
    cutout: cutout,
    labels: labels,
  });
  
  const data = {
    datasets: [
      createDataset(projectnameLabels, 'rgba(153, 102, 255, 0.2)', 'rgba(153, 102, 255, 1)', '30%', 'project_name'),
      createDataset(projectTypeLabels, 'rgba(75, 192, 192, 0.2)', 'rgba(75, 192, 192, 1)', '20%', 'category'),
      createDataset(substationnameLabels, 'rgba(54, 162, 235, 0.2)', 'rgba(54, 162, 235, 1)', '15%', 'ss_name'),
      createDataset(voltageLabels, 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 1)', '10%', 'ss_voltage'),
    ],
  };
  
  const options = useMemo(() => ({
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const layer = context.dataset.label;
            const name = context.dataset.labels[context.dataIndex];
            return `${layer} - ${name}: ${context.raw}`;
          },
        },
      },
      datalabels: {
        color: '#000',
        display: true,
        formatter: (value, context) => context.dataset.labels[context.dataIndex] || '',
        anchor: 'center',
        align: 'center',
        font: {
          weight: 'italic',
        },
        offset: 10,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const element = elements[0];
        const datasetIndex = element.datasetIndex;
        const index = element.index;
        const selectedLabel = data.datasets[datasetIndex].labels[index];
        const datasetLabel = data.datasets[datasetIndex].label;
        console.log('Filtered Value:', { type: datasetLabel, name: selectedLabel });
        setSelectedData({ type: datasetLabel, name: selectedLabel });
      }
    },
  }), [data.datasets]); // Add dependencies if needed
  
  // return <Doughnut data={data} options={options} />;
  

  useEffect(() => {
    if(selectedData && selectedData.type != "ss_name" && selectedData.type != "ss_voltage" ){



      const filter = project.filter(item => item[selectedData.type] === selectedData.name);
  
      const organizationIds = filter.map(item => item.owner_id);
      setOrganizationId(organizationIds);

      console.warn("Check organization IDS" ,organizationIds);
    


    }else if(selectedData && (selectedData.type === "ss_name" ) ){


      const filtered = subStation.filter(item => item[selectedData.type] === selectedData.name);

      // Check if filtered array has elements
      if (filtered.length > 0) {
          // Filter the project based on substation_id matching any ss_id in the filtered array
          const filter = project.filter(item =>
              filtered.some(subItem => item.substation_id === subItem.ss_id)
          );
      
          // Extract the organizationIds from the filtered project data
          const organizationIds = filter.map(item => item.owner_id);
          setOrganizationId(organizationIds);
      
          console.warn("Check organization IDS 1", organizationIds);
          console.warn("Check organization Filter", filter);
      } else {
          console.warn("No matching substations found.");
      

    }
  }
    
  }, [selectedData]);
  
  
  
  if (loading) {
    return (
      <div className="w-100">
      <div className="d-flex justify-content-end">
        {/* <button className="btn btn-danger m-2" onClick={handleRefresh}>Clear</button> */}
        <Skeleton Square={true} height={40} width={80} />
      </div>
      <div
        style={{
          width: '100%',
          height: '550px',
          margin: 0,
          padding: '0px 100px',
          boxSizing: 'border-box',
        }}
        className="d-flex justify-content-center align-items-center"
      >
                <Skeleton circle={true} height={300} width={300} />

      </div>
    </div>
    
    );
  }

  if (error) {
    return (
    <div
    style={{
      width: '100%',
      height: '550px',
      margin: 0,
      padding: '0px 100px',
      boxSizing: 'border-box',
    }}
    className="d-flex justify-content-center align-items-center"
  >
    <h5>Data not found</h5></div>
    )
  }

  return (
    <div className="w-100">
      <div className="d-flex justify-content-end">
        <button className="btn btn-danger m-2" onClick={handleRefresh}>Clear</button>
      </div>
      <div
        style={{
          width: '100%',
          height: '550px',
          margin: 0,
          padding: '0px 100px',
          boxSizing: 'border-box',
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
};


export default SubstationDoghtnut;
