import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Skeleton } from '@mui/material';
import LeftSidebar from '../Sidebar/LeftSideBar';

// Use environment variable for API URL
const url = process.env.REACT_APP_API_URL;

function Home() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/dashboard`);
        setData(response.data);
        // console.log("Data is", response.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
        setError('Error fetching data');
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchData();
  }, []);

  if (loading) {
    // Render skeletons while loading
    return (
      <div className="home d-flex flex-row justify-content-around align-items-stretch pt-5 gap-3">
      {[...Array(2)].map((_, rowIndex) => (
        <div key={rowIndex} className="d-flex flex-column justify-content-around gap-3">
          {[...Array(4)].map((_, colIndex) => (
            <Skeleton
              key={colIndex}
              variant="rectangular"
              width={600}
              height={170}
            />
          ))}
        </div>
      ))}
    </div>
    );
  }

  if (error) {
    // Render error message if data fetching failed
    return (
      <div
        className="d-flex justify-content-center align-items-center text-center text-danger"
        style={{ width: '100%', height: '100%' }}
      >
        <div>
          {error}
        </div>
      </div>
    );
  }
  return (
    <div className="home d-flex flex-row justify-content-center">
      {Object.keys(data).map(section => (
        <div key={section} className="card container mx-5  mb-3 p-3 rounded ">
          <h3 className="p-3">{section.charAt(0).toUpperCase() + section.slice(1)}</h3>
          {Object.keys(data[section]).map(key => (
            <div key={key} className="d-flex flex-column ml-3 p-2 rounded border m-4 text-center text-white">
              <div className="p-3 fs-3">
                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}</strong>
              </div>
              <div className="text-center fs-2"> {data[section][key]}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Home;
